h1{
  text-align: center;
}

section {
  margin-bottom: 50px;
}

.miniImage {
  position: absolute;
  right: 0px;
  bottom: 0px; 
  width: 35%;
  height: 35%;
}

.fullImage {
  position:absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

img {
  -webkit-transition: width .3s ease-in-out, height .3s ease-in-out;
  -moz-transition: width .3s ease-in-out, height .3s ease-in-out;
  -o-transition: width .3s ease-in-out, height .3s ease-in-out;
  transition: width .3s ease-in-out, height .3s ease-in-out;
}

.is-active {
  color: #000000 !important;
  background-color: #96be25 !important;
}

.screenshotContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav-link {
  color: #96be25;
}

.nav-link:focus {
  color: #96be25;
}

.nav-link:hover {
  color: #7a9729;
}

.lead {
  margin-bottom: 40px;
}

p > b {
  color: #5c721f;
}

.highlight-card {
  border-color: #5c721f;
  border-width: 3px;
}